/* General Reset and Box-sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
}

/* Hero Section Container */
.hero-container {
    /* max-height: 100dvh; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vh 5vw;
    /* Added more padding on the top (10vh) */
    color: white;
}

/* Flex container for content */
.hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

@media (min-width: 768px) {
    .hero-content {
        flex-direction: row;
        justify-content: space-between;
    }
}

/* Left Side: Text Section */
.hero-text {
    flex: 1;
    padding-right: 2rem;
    margin-bottom: 2rem;
    text-align: left;
}

@media (max-width: 768px) {
    .hero-text {
        text-align: left;
        margin-bottom: 0;
    }
}

.hero-title {
    font-size: 2.0rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-family: 'Georgia', serif;
    text-align: left;
}

@media (min-width: 768px) {
    .hero-title {
        font-size: 3.5rem;
    }
}

.hero-description {
    font-size: 1.125rem;
    /* Relative font size for body text */
    margin-bottom: 2rem;
    max-width: 600px;
    color: #cec8c8;
}

@media (min-width: 768px) {
    .hero-description {
        font-size: 1.25rem;
    }

}

/* Button Styling */
.hero-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

@media (min-width: 768px) {
    .hero-buttons {
        justify-content: flex-start;
    }
}

.hero-btn {
    padding: 1rem 1.5rem;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.demo-btn {
    background-color: white;
    color: black;
}

.demo-btn:hover {
    background-color: #f0f0f0;
}

.explore-btn {
    background-color: transparent;
    color: white;
    border: 2px solid white;
}

.explore-btn:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Right Side: Image */
.hero-image-container {
    flex: 1;
    position: relative;
    max-width: 400px;
    width: 100%;
    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .hero-image-container {
        margin-bottom: 0;
    }
}

.hero-image {
    margin-top: 40px;
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
}