.about {
    background-color: black;
    height: 80dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .title {
    font-family: 'Playfair Display', serif; /* Or a similar serif font */
    font-size: 5rem;
    line-height: 1;
    text-align: center;
  }
  
  .lumis {
    font-family: 'Playfair Display', sans-serif; /* Or a similar sans-serif font */
    font-size: 5rem;
    line-height: 1;
    color: green;
    font-weight: bold;
    font-style: italic;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  
  
  