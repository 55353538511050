.team-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    flex-wrap: wrap; /* Allows wrapping for smaller screens */
  }
  
  .text-content {
    max-width: 40%;
  }
  
  .text-content h1 {
    font-size: 5rem;
    font-weight: bolder;
  }
  
  .highlight {
    color: green; /* Set the blue color from the image */
  }
  
  .text-content p {
    margin: 10px 0;
  }
  
  .apply-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #000;
    color: #9e9898;
    border: 1px solid grey;
    cursor: pointer;
    font-family: "Merriweather", serif;
  }
  
  .apply-btn:hover {
    opacity: 0.8;
  }
  
  .sub-text {
    color: gray;
    margin-top: 30px;
  }
  
  .avatar-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%; /* Adjust the width for larger screens */
    flex-basis: 100%; /* Full width on smaller screens */
    margin-top: 20px;
  }
  
  .avatar-image {
    width: 100%;
    height: auto;
    max-width: 400px; /* Ensure the image doesn't get too large */
    border-radius: 15px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .team-section {
      flex-direction: column;
      text-align: left;
    }
  
    .text-content {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .avatar-grid {
      max-width: 100%; /* Full width on mobile */
    }
  
    .avatar-image {
      max-width: 80%; /* Scale image for smaller devices */
    }
  }
  