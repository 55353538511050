.benefits-section {
    padding: 40px 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .content-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .section-label {
    color: green; /* Accent color for the small label */
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .main-heading {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: white; /* Dark color for the main heading */
  }
  
  .subtext {
    font-size: 18px;
    color: #6b6b6b; /* Grey color for the subtext */
    margin-bottom: 30px;
  }
  
  .cta-link {
    color: #007b77; /* Call-to-action color */
    font-size: 16px;
    text-decoration: underline;
    font-weight: bold;
  }
  
  .benefits-section img {
    width: 60%;
    height: auto;
    margin-top: 20px;
    max-width: 600px; /* Ensure image doesn't get too large */
    background-blend-mode: color-burn;
  }
  
  /* Responsiveness for tablets */
  @media (max-width: 768px) {
    .main-heading {
      font-size: 28px; /* Slightly smaller heading on tablets */
    }
  
    .subtext {
      font-size: 16px; /* Adjust subtext size for better readability on smaller screens */
    }
  
    .benefits-section img {
      width: 80%; /* Increase image width to fill more space */
      max-width: 500px;
    }
  }
  
  /* Responsiveness for mobile */
  @media (max-width: 480px) {
    .main-heading {
      font-size: 24px; /* Further reduce heading size */
    }
  
    .subtext {
      font-size: 14px; /* Smaller subtext for mobile devices */
    }
  
    .benefits-section img {
      width: 90%; /* Image takes up most of the width on mobile */
      max-width: 400px; /* Restrict the max width for small devices */
    }
  }
  