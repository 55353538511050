/* Container for the entire section */
.multinational-section {
  position: relative;
  width: 100%;
  /* height: 50vh; */
  /* Full viewport height */
  /* background-color: ; */
  /* background: url('https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center/cover; Background image */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

/* Content container for text and button */
.content {
  margin-left: 10%;
  max-width: 600px;
}

/* Main heading */
h1 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 20px;
}

/* Paragraph text */
p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

/* Button styling */
.contact-btn {
  padding: 12px 25px;
  font-size: 1rem;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 30px;
}

.contact-btn:hover {
  background-color: rgb(90, 85, 85);
  color: rgb(185, 182, 182);
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .content {
    margin-left: 5%;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  /* Container for the entire section */
  .multinational-section {
    align-items: start;
  }

  .contact-btn {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
}