/* Container for the whole testimonial section */
.testimonial-container {
    display: flex;
    height: 70dvh;
    align-items: center;
    justify-content: space-between;
    background-color: #0b0b0f;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 4px 8px rgba(1, 1, 1, 0.1);
    max-width: 1000px;
    margin: 0 auto; /* Centers the testimonial */
  }
  
  /* Left image section */
  .testimonial-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-avatar {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  /* Right content section */
  .testimonial-content {
    flex: 2;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
  }
  
  .quote-icon {
    width: 10px;
    height: 10px;
  }
  
  .testimonial-text {
    font-size: 1.2rem;
    color: #838181;
    margin-bottom: 20px;
    font-style: italic;
  }
  
  .testimonial-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: green;
    font-style: italic;
  
  }
  
  .testimonial-title {
    font-size: 0.875rem;
    color: #6c757d;
    font-style: italic;
  }
  
  /* For mobile responsiveness */
  @media (max-width: 768px) {
    .testimonial-container {
      flex-direction: column;
      text-align: center;
      padding: 20px;
      height: auto;
      margin: 10px;
    }
    
    .testimonial-content {
      padding-left: 0;
      padding-top: 20px;
    }
  }
  