/* Main container for the extended team section */
.extended-team-section {
  padding: 50px 20px;
  /* background-color: #111; */
  color: white;
}

/* Extended team heading */
.extended-team-section h2 {
  font-size: 2rem;
  margin-bottom: 40px;
  text-align: left;
  color: white;
}

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

/* Team container with responsive grid */
.team-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Each team member card */
.team-member {
  /* background-color: #222; */
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  transition: transform 0.3s;
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.team-member:hover {
  transform: translateY(-5px);
}

/* Team member icon above name */
.team-member-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

/* Team member name styling */
.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: white;
}

/* Position container (role + LinkedIn icon) */
.position-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.role {
  font-size: 1rem;
  background-color: #333;
  color: #ccc;
  padding: 5px 10px;
  border-radius: 4px;
}

/* LinkedIn icon positioned to the right of the role */
.position-container a {
  margin-left: 10px;
  font-size: 1.2rem;
  color: grey;
}

.position-container a:hover {
  color: #b8deef;
}

/* Description styling */
.description {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #ccc;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .extended-team-section {
    padding: 20px;
  }

  .team-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .team-member h3 {
    font-size: 1.2rem;
  }

  .description {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .team-container {
    grid-template-columns: 1fr;
  }

  .team-member h3 {
    font-size: 1.1rem;
  }

  .description {
    font-size: 0.8rem;
  }
}