/* Container styling for the snapshot section */
.snapshot-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    color: black;
  }
  .snapshot-section h2 {
    color: #f9f9f9;
  }
  
  /* Styling for the left content */
  .left-content {
    flex: 1;
    padding-right: 20px;
    max-width: 300px;
  }
  
  .left-content h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Styling for the right content (Snapshot Cards) */
  .right-content {
    flex: 3;
    display: flex;
    gap: 20px;
  }
  
  /* Styling for each snapshot card */
  .snapshot-card {
    position: relative;
    padding: 20px;
    flex: 1;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .snapshot-card h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .snapshot-card p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 0;
  }
  
  /* Spacer between h3 and p */
  .spacer {
    flex-grow: 1;
  }
  
  /* Background colors for the cards */
  .snapshot-card.yellow {
    background-color: #ffe3b3;
  }
  
  .snapshot-card.off-white {
    background-color: #f9f9f9;
  }
  
  .snapshot-card.beige {
    background-color: #ece1d5;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .snapshot-section {
      flex-direction: column;
    }
  
    .right-content {
      flex-direction: column;
      gap: 20px;
    }
  
    .snapshot-card {
      flex: none;
      height: auto;
    }
  
    .snapshot-card p {
      position: static;
      margin-top: 20px;
    }
  
    .left-content {
      text-align: center;
      padding-right: 0;
      margin-bottom: 20px;
    }
  }
  