.footer {
    background-color: #0a0c0c;
    color: #c5c3c3;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    width: 80%;
    max-width: 1200px;
    flex-wrap: wrap;
}

.footer-section {
    flex: 1;
    margin: 20px;
}

.footer-logo {
    width: 150px;
    margin-bottom: 20px;
}

.footer-description {
    font-size: 14px;
    margin-bottom: 20px;
}

.footer-section h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    text-decoration: none;
    color: #504b4b;
    font-size: 14px;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.footer-social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.footer-social p {
    margin-right: 15px;
}

.footer-social a {
    color: #8c8b8b;
    margin-right: 10px;
}

.footer-social a:hover {
    color: #ccc;
}
.footer-social p{
    margin-top: 30px;
}