/* General Styling */
.job-listing {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    color: #a9a6a6;
    border-radius: 8px;
  }
  
  h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .intro-text {
    text-align: center;
    margin-bottom: 30px;
    color: #b5b5b5;
  }
  
  /* Job Card */
  .job-card {
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 1px solid #282626;
  }
  
  .job-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
  }
  
  .job-title h3 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .job-title p {
    margin: 0;
    color: #888;
  }
  .apply-btn{
    border: none;
  }


  .apply-btn button {
    background-color: rgb(26, 24, 24);
    color: #484545;
    border: none;
    padding: 15px 15px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .apply-btn button:hover {
    opacity: 0.8;
  }
  
  /* Job Details */
  .job-details {
    margin-top: 20px;
  }
  
  .job-details p {
    margin: 8px 0;
    font-size: 1rem;
  }
  
  strong {
    color: #a09d9d;
  }
  
  /* Expandable Sections */
  .job-description {
    margin-top: 20px;
  }
  
  .job-description h4 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .job-description p,
  .job-description ul {
    font-size: 1rem;
    color: #636262;
  }
  
  .job-description ul {
    padding-left: 20px;
  }
  
  .job-description ul li {
    margin-bottom: 8px;
  }
  
  /* Toggle Button */
  .toggle-btn {
    text-align: right;
    margin-top: 10px;
  }
  
  .toggle-btn button {
    background-color: transparent;
    border: none;
    color: #aaaaab;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .toggle-btn button:hover {
    opacity: 0.8;
  }
  
  /* Media Query for smaller screens */
  @media (max-width: 768px) {
    .job-overview {
      flex-direction: column;
      text-align: center;
    }
  
    .apply-btn {
      margin-top: 15px;
    }
  }
  