.features-container {
    display: flex;
    flex-direction: column;
    max-width: 100dvw;
    margin: auto;
    gap: 50px;
    padding: 50px 0;
  }
  
  .feature {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  
  .feature-image img {
    width: 300px;
    height: auto;
  }
  
  .feature-text {
    flex: 1;
  }
  
  .feature-text h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .feature-text p {
    font-size: 0.5rem;
    color: #555;
    /* line-height: 1.5; */
  }
  
  /* Image on the left */
  .image-left {
    flex-direction: row; /* Default layout: image left, text right */
  }
  
  /* Image on the right */
  .image-right {
    flex-direction: row-reverse; /* Reverse layout: image right, text left */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .feature {
      flex-direction: column;
      /* text-align: center; */
    }
  
    .feature-image img {
      width: 90%;
      margin: 0 auto;
    }
    .features-container {
    max-width: 90dvw;

    }
  }
  