header {
    background-color: black;
    padding: 0.7rem 1rem;
}

.navbar-container {
    max-width: 1440px;
    width: 100%;
    padding: 0 0;
    margin: auto;
}

.navbar-container nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-container .nav-link {
    display: flex;
}

.navbar-container nav .logo img {
    cursor: pointer;
    width: 80px;
    height: 80px;
}

.navbar-container .nav-link li {
    list-style: none;
    margin: 0 0.5rem;
}

.navbar-container ul li a {
    color: white;
    font-size: 1.1rem;
    text-decoration: none;
    padding: 0.7rem 1rem;
    display: block;
    border-radius: 4px;
}

.navbar-container ul li a:hover
{
    /* background-color: green; */
    color: grey;
}

.icon {
    cursor: pointer;
    display: none;
}

@media(max-width: 768px) {
    .icon {
        display: block;
    }

    .navbar-container .nav-link {
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        width: 250px;
        height: 40vh;
        top: -800px;
        right: 0;
        background-color: rgb(15, 13, 13);
        z-index: 10;
        transition: all 0.7s ease-in;
    }

    .nav-link.active {
        top: 10%;
        transition: all 0.3s ease-in;
    }
}