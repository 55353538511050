/* Main container */
.infrastructure-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    margin: auto;
    /* background: linear-gradient(360deg, #090505, #151315); */
  }
  
  /* Text and Image side-by-side container */
  .text-and-image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 50px;
  }
  
  /* Text Content Styling */
  .text-content {
    max-width: 600px;
    flex: 1;
  }
  
  .text-content h1 {
    font-size: 2.8rem;
    color:white;
    margin-bottom: 20px;
}
  
  .text-content p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.8;
  }
  
  /* Image container */
  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .globe-image {
    max-width: 250px;
    width: 100%;
    height: auto;
    margin-top: 40px;
  }
  
  /* Service cards container */
  .services-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    max-width: 1200px;
    width: 100%;
    justify-items: center;
  }
  
  /* Individual Service Card */
  .service-card {
    /* background-color: white; */
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  
  .service-card h3 {
    font-size: 1.3rem;
    color: whitesmoke;
    margin-bottom: 15px;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #666;
    flex-grow: 1;
  }
  
  .icon {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .text-and-image-container {
      flex-direction: column;
    }
  
    .globe-image {
      margin-bottom: 30px;
    }
  
    .services-list {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .text-content h1 {
      font-size: 2rem;
    }
  
    .text-content p {
      font-size: 1rem;
    }
  
    .service-card h3 {
      font-size: 1.2rem;
    }
  
    .service-card p {
      font-size: 0.9rem;
    }
  
    .icon {
      font-size: 1.5rem;
    }
    .infrastructure-section {
        padding: 10px;
    }
  }
  