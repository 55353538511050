.accordion {
    width: 90dvw;
    margin: 0 auto;
  }
  
  .accordion h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .accordion-item {
    border-bottom: 1px solid #656464;
    padding: 15px 0;
  }
  .question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .accordion-header h4 {
    margin: 0;
    color: #e0dddd;
  }
  
  .accordion-header span {
    font-size: 18px;
    color: #888;
  }
  
  .accordion-body {
    margin-top: 10px;
    /* font-size: 16px; */
    color: #939191;
  }
  
  .accordion-body p {
    margin: 0;
  }
