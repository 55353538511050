.features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: black;
  color: white;
}

@keyframes appear {
  from {
    opacity: 0;
    scale: 0.5;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

.feature {
  flex: 1 1 45%;
  margin: 1rem;
  max-width: 600px;
  margin: auto;
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 0% cover 40%;
}

.features h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.feature h4 {
  font-size: 1.0rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: green;
}

.feature-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 1rem;
}

.img-container {
  justify-content: center;
  align-items: center;
}

.features,
.feature p {
  color: #888;
  line-height: 1.6;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .feature {
    flex: 1 1 100%;
  }

  .feature-content {
    align-items: center;
    text-align: center;
  }
}