/* Container Styling */
@keyframes appear {
    from {
        scale: 0.5;
        opacity: 0;
    }

    to {
        scale: 1;
        opacity: 1;
    }
}
.qa-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 90vh;
    /* Adjust to fit the desired section height */
    background-color: black;
    /* Set a light background color similar to the image */
    /* background-image: url('https://img.freepik.com/free-vector/abstract-blue-circle-black-background-technology_1142-12714.jpg');  */
    background-size: contain;
    /* Ensures the image covers the container */
    background-position: center;
    /* Centers the image */
    background-repeat: no-repeat;
    /* Prevents tiling */
    animation: appear linear;
    animation-timeline: view();
    animation-range: entry 0% cover 40%;
}



/* Text Styling */
.qa-text h1 {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 5.5rem;
    /* Adjust size for responsiveness */
    font-weight: bold;
    line-height: 1.5;
    color: rgb(245, 245, 245);
    /* Match the dark green color */
}

/* Placeholder Icon (Replace with your actual icon) */
.icon-placeholder {
    font-size: 1.2rem;
    /* Icon size */
    color: #0a2e1d;
    /* Match icon color */
}

/* Media Queries */
@media (max-width: 768px) {
    .qa-text h1 {
        font-size: 1.8rem;
    }

    .qa-container {
        height: 50vh;
        /* Adjust to fit the desired section height */
    }

    .qa-icon {
        width: 25px;
        height: 25px;
    }
}