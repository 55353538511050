/* Container for the entire team section */
.core-team-section {
    padding: 50px 20px;
    color: white;
}

/* Core team heading aligned to the left */
 h5 {
    font-size: 1rem;
    margin-bottom: 40px;
    color: #d5cdcd;
    text-align: left;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin-left: 10px;
}
.core-team-section p{
    color: #d5cdcd;
}

/* Team container styling */
.team-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

/* Team member card styling */
.team-member {
    /* background-color: #333; */
    border-radius: 8px;
    overflow: hidden;
    text-align: left;
    padding: 20px;
    transition: transform 0.3s;
}

/* Add hover effect on team member cards */
.team-member:hover {
    transform: translateY(-5px);
}

/* Team member image styling (equal size for all images) */
.team-member-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
}

/* Team member info styling */
.team-member-info h3 {
    font-size: 1.3rem;
    margin: 10px 0 5px;
}

/* Position container for role and LinkedIn icon */
.position-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

/* Position (role) styling with border */
.role {
    font-size: 1rem;
    color: #ccc;
    border: 1px solid #ccc;
    padding: 2px 10px;
    border-radius: 4px;
}

/* LinkedIn icon styling - placed 10px to the right of role */
.position-container a {
    color: grey;
    text-decoration: none;
    font-size: 1.5rem;
    margin-left: 10px;
    margin-top: -30px;
}

.position-container a:hover {
    color: #bdddec;
}

/* Responsive grid layout */
@media (max-width: 768px) {
    .core-team-section {
        padding: 20px;
    }

    .team-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .team-member-info h3 {
        font-size: 1.1rem;
    }

    .team-member-img {
        height: 250px;
        /* Adjusted for smaller screens */
    }
}

@media (max-width: 480px) {
    .team-container {
        grid-template-columns: 1fr;
    }

    .team-member-img {
        height: 300px;
        /* Further adjustment for small screens */
    }
}