.component-container {
    display: flex;
    justify-content: space-between;
    width: 100vw; /* Adjusted for proper responsive scaling */
    margin: 50px auto;
    background-image: url('https://www.shutterstock.com/image-photo/blue-neon-illustration-background-soft-600nw-2471352461.jpg');
    background-size: cover; /* Ensures the image covers the container */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling */
    padding: 20px; /* Adds padding for better spacing */
    border-radius: 10px; /* Optional: Adds rounded corners */
    height: auto; /* Content height adjusts automatically */
}

.avatar-container {
    background-color: transparent;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-image: url('https://plus.unsplash.com/premium_photo-1682096252599-e8536cd97d2b?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwc21pbGluZ3xlbnwwfHwwfHx8MA%3D%3D');
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Centers the image in the container */
    background-repeat: no-repeat; /* Prevents tiling */
    overflow: hidden; /* Ensures the image respects container boundaries */
}

.get-in-touch-btn {
    width: 100px;
    height: 40px;
    border: 2px solid #c5c2c2;
    border-radius: 40px;
    font-weight: 400;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    text-align: center; /* Ensure text alignment */
    padding: 0; /* Reset padding */
    margin: 0; /* Reset margin */
}

.get-in-touch-btn p {
    margin: 0; /* Remove default margin from the <p> tag */
    line-height: 1; /* Ensure line-height doesn't add extra space */
}

.confirm-questions p {
    margin-top: 10px;
    color: #aeb2b5;
}

/* Media Queries */

/* For small screens (mobile) */
@media (max-width: 768px) {
    .component-container {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        width: 95vw; /* Increase width slightly for better spacing */
        padding: 15px; /* Reduce padding for smaller screens */
    }

    .avatar-container {
        margin-bottom: 15px; /* Add spacing between stacked items */
    }

    .confirm-questions h4 {
        text-align: center; /* Center-align heading text */
    }

    .confirm-questions p {
        text-align: center; /* Center-align paragraph text */
    }

    .get-in-touch-btn {
        margin-top: 15px; /* Add spacing between button and other content */
    }
}

/* For medium screens (tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
    .component-container {
        flex-direction: row; /* Keep row layout */
        width: 90vw; /* Adjust container width */
        padding: 20px;
    }

    .avatar-container {
        width: 50px; /* Slightly larger avatar */
        height: 50px;
    }

    .get-in-touch-btn {
        width: 120px; /* Larger button for better visibility */
        height: 50px;
    }
}

/* For large screens (desktops) */
@media (min-width: 1025px) {
    .component-container {
        width: 90vw; /* Constrain width for large screens */
        padding: 30px;
    }

    .avatar-container {
        width: 70px; /* Larger avatar for desktops */
        height: 70px;
    }

    .get-in-touch-btn {
        width: 130px; /* Larger button for better aesthetics */
        height: 50px;
    }
}
