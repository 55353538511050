/* NotFoundPage.css */

/* Main container styling */
.notfound-container {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000; /* Black background */
  }
  
  .notfound-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff; /* White text */
    text-align: center;
  }
  
  .notfound-image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .ghost-image {
    width: 80%;
    max-width: 300px; /* Limit max size for larger screens */
    margin-right: 40px;
  }
  
  .notfound-text {
    flex: 1;
    text-align: left;
  }

  
  .notfound-text h1 {
    font-size: 4rem;
    font-family: 'Cormorant Garamond', serif;
    margin: 0;
    line-height: 1;
  }
  
  .notfound-text p {
    font-size: 1.2rem;
    margin: 10px 0 20px 0;
    color: #9e9c9c; 
  }
  
  .back-btn {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-btn:hover {
    background-color: #ddd;
  }
  
  @media (max-width: 768px) {
    .notfound-content {
      flex-direction: column;
    }
  
    .notfound-image {
      justify-content: center;
      margin-bottom: 20px;
    }
  
    .notfound-text h1 {
      font-size: 3rem;
    }
  
    .ghost-image {
      width: 50%;
    }
  }
  
  @media (max-width: 480px) {
    .notfound-text {
        flex: 1;
        text-align: center;
      }
    
    .notfound-text h1 {
      font-size: 2.5rem;
    }
  
    .notfound-text p {
      font-size: 1rem;
    }
  
    .back-btn {
      padding: 8px 16px;
    }
  }
  