.header {
    position: relative;
    background-color: black;
    color: #a1a4a1;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
  }
  
  .background-image {
    position: absolute;
    inset: 0;
    background: url("https://upload.wikimedia.org/wikipedia/commons/f/f2/Fase_16.jpg") center/contain no-repeat;
    opacity: 0.4;
    z-index: 1;
  }
  
  .heading {
    font-size: 1.5rem;
    font-weight: bold;
    font-style: italic;
    font-family: Georgia, 'Times New Roman', Times, serif;
    z-index: 2;
    line-height: 1.5;
  }
  
  @media (min-width: 768px) {
    .heading {
      font-size: 1rem;
    }
  }
  
  @media (min-width: 1024px) {
    .heading {
      font-size: 2rem;
    }
  }
  