/* Testimonials.css */

/* Section Styling */
.testimonials-section {
    text-align: center;
    padding: 50px 20px;
    /* background-color: #f9fafb; */
  }
  
  /* Header Styling */
  .testimonials-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #cecccc;
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .testimonials-header p {
    font-size: 1.2rem;
    color: #666;
    margin: 10px 0 20px;
  }
   
  /* Testimonials Grid */
  .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    margin-top: 40px;
  }
  
  /* Testimonial Card */
  .testimonial-card {
    /* background-color: rgb(27, 24, 24); */
    border-radius: 10px;
    background-image: linear-gradient(to bottom right, rgb(38, 33, 33), black);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
  }
  
  /* Testimonial Header */
  .testimonial-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .testimonial-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
    /* margin-top: -20px; */
  }
  
  /* Testimonial Text */
  .testimonial-text {
    font-style: italic;
    color: #555;
  }
  
  .testimonial-card h3 {
    font-size: 1.1rem;
    margin: 0;
    color: #c0bebe;
    font-family: "Merriweather", serif;
  }
  
  .testimonial-card h4 {
    font-size: 0.9rem;
    color: #0c9d63;
  }

  @media (max-width: 768px) {
    .testimonials-header h1 {
      font-size: 2rem;
    }
  
    .cta-button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .testimonials-header h1 {
      font-size: 1.8rem;
    }
  
    .cta-button {
      padding: 8px 18px;
      font-size: 0.8rem;
    }
  }
  