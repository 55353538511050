.features-section {
    /* background-color: #faf6f4; Light background similar to the image */
    padding: 60px 40px;
    display: flex;
    justify-content: center;
  }
  
  .feature-container {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    width: 100%;
    flex-wrap: wrap; /* Allow the feature items to wrap on smaller screens */
  }
  
  .feature-item {
    background-color: #1d1b1b; /* White background */
    padding: 20px;
    margin: 0 20px;
    width: 38%; /* Default width for larger screens */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Smooth transition for width changes */
  }
  
  .feature-title {
    font-size: 24px;
    color: #c3c0c0;
    margin-bottom: 15px;
  }
  
  .feature-description {
    font-size: 16px;
    color: #6b6969;
    margin-bottom: 20px;
  }
  
  .feature-list {
    list-style-type: none;
    padding: 0;
  }
  
  .feature-list li {
    font-size: 16px;
    color: #484545;
    margin-bottom: 10px;
    position: relative;
    padding-left: 25px;
  }
  
  .feature-list li::before {
    content: '✔'; /* Checkmark symbol */
    position: absolute;
    left: 0;
    color: #00b894; /* Color of the checkmark */
    font-size: 18px;
  }
  
  /* Responsive Styles */
  
  /* For tablets and smaller devices */
  @media (max-width: 768px) {
    .feature-item {
      width: 90%; /* Full width for each feature item on tablets */
      margin: 20px 0; /* Add margin between stacked feature items */
    }
  
    .feature-title {
      font-size: 22px; /* Slightly smaller font on tablets */
    }
  
    .feature-description, .feature-list li {
      font-size: 15px; /* Reduce text size for better readability */
    }
  }
  
  /* For mobile devices */
  @media (max-width: 480px) {
    .features-section {
      padding: 20px 10px; /* Reduce padding on mobile */
    }
  
    .feature-item {
      width: 90%; /* Full width for mobile */
      margin: auto ; /* Add space between the items */
      margin-bottom:  20px;

    }
  
    .feature-title {
      font-size: 20px; /* Smaller heading for mobile */
    }
  
    .feature-description, .feature-list li {
      font-size: 14px; /* Smaller font size for mobile screens */
    }
  }
  