/* Container for the entire section */
.metrics-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 80px;
    color: #fff;
    height: 100vh;
    flex-wrap: wrap;
    box-sizing: border-box;
}

  /* Left Column (Text Content) */
.metrics-text {
    flex: 1;
    max-width: 600px;
    box-sizing: border-box;
}

.section-subtitle {
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #9c9c9c;
    margin-bottom: 10px;
}

.section-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.2;
    font-family: "Merriweather", serif;
}

.section-description {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #b0b0b0;
    margin-bottom: 40px;
}

/* Stats Section */
.metrics-stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.metric {
    margin-bottom: 30px;
    flex-basis: 45%;
}

.metric h3 {
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;
}

.metric p {
    font-size: 1rem;
    color: #b0b0b0;
}

.metric strong {
    color: #fff;
}

/* Right Column (Image with Overlay) */
.metrics-image {
    flex: 1;
    height: 100%;
    background-size: 100px 150px;
    background: url('https://images.unsplash.com/photo-1617777938240-9a1d8e51a47d?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center/cover;
    position: relative;
    margin-left: 20px;
    margin-top: 20px;
}

/* Dark overlay over the image */
.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
}

/* Responsive Styles */

/* Medium screens: Tablets and smaller desktops */
@media (max-width: 1024px) {
  .metrics-section {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 20px;
    height: auto;
  }

  .metrics-text {
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 2.2rem;
  }

  .metrics-image {
    width: 100%;
    height: 300px;
    margin: 20px 0;
  }
}

/* Small screens: Mobile devices */
@media (max-width: 768px) {
  .metrics-section {
    padding: 20px;
    height: auto;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-description {
    font-size: 1rem;
  }

  .metric h3 {
    font-size: 1.8rem;
  }

  .metrics-image {
    height: 250px;
  }
}

/* Extra Small screens: Mobile portrait */
@media (max-width: 480px) {
  .section-title {
    font-size: 1.8rem;
  }

  .metrics-image {
    height: 200px;
  }

  .metric {
    flex-basis: 100%;
  }

  .metric h3 {
    font-size: 1.5rem;
  }
  
  .metric p {
    font-size: 0.9rem;
  }

  .metrics-stats {
    flex-direction: column;
    align-items: flex-start;
  }
}
