/* ContactForm.css */

/* Main container for the contact section */
.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

input[type='text'],
input[type='email'],
textarea {
  font-size: 16px;
  background-color: rgb(29, 26, 26);
  border: 1px solid black;
  color:#ddd;
  border-color: #110f0f;
}

.contact-container {
  width: 80%;
  max-width: 1200px;
  background-color: #090909;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

/* Left side image styling */
.contact-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.contact-image img {
  width: 90%;
  max-width: 300px;
}

/* Right side form styling */
.contact-form {
  flex: 1;
  padding: 40px;
}

.form-title {
  font-size: 2rem;
  font-weight: bold;
  color: green;
  margin-bottom: 20px;
  text-align: left;
}

.form-title span {
  color: #e9e5e3;
}

/* Form input fields styling */
.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.form-control::placeholder {
  color: #999;
}

/* Submit button styling */
.submit-btn {
  background-image: linear-gradient(45deg, #96de96, white);
  color: black;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.submit-btn:hover {
  background-color: #0c0f0d;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-image {
    padding: 10px;
  }

  .contact-form {
    padding: 20px;
  }

  .form-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 480px) {
  .contact-container {
    width: 90%;
  }

  .form-title {
    font-size: 1.6rem;
  }

  .form-control {
    padding: 8px;
  }

  .submit-btn {
    font-size: 0.9rem;
  }
}